import React from "react";
import Layout from "../components/layout";
import Button from "../components/button";

export default function DesignSystem({
  pageContext: { language, title, id },
  data,
}) {
  return (
    <Layout locale={language} title={title} pageId={id}>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
              Design system
            </h1>
          </div>
        </div>
      </div>
      <div className="px-4  sm:px-6 lg:px-8">
        <div className="prose mx-auto ">
          <h2>Buttons</h2>
          <h3 className="text-gray">Action buttons</h3>
          <div className="flex items-start flex-none flex-col">
            <Button icon="arrow-right">Open SCREENPOLO NOW</Button>&nbsp;
            <Button icon="flask">Discover the API</Button>&nbsp;
            <Button inverted icon="flask">Discover the API Inverted</Button>&nbsp;
            <Button>
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
              Download the application
            </Button>&nbsp;
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
              How to Upgrade
            </button>
          </div>
          <h3 className="text-gray-500">Enable, disabled, loading</h3>
          <div className="flex items-start flex-none">
            <Button>Button</Button>&nbsp;
            <Button icon="sync-alt">Button</Button>&nbsp;
            <Button disabled>Disabled</Button>&nbsp;
            <Button loading>Loading</Button>
          </div>
          <h3 className="text-gray-500">Color variations</h3>
          <div className="flex items-start flex-none">
            <Button color="gray">Button</Button>&nbsp;
            <Button color="green">Button</Button>&nbsp;
            <Button color="pink">Button</Button>&nbsp;
            <Button color="indigo">Button</Button>&nbsp;
            <Button color="red">Button</Button>&nbsp;
            <Button color="red" disabled>
              Disabled
            </Button>
            &nbsp;
            <Button color="orange" loading>
              Loading
            </Button>
          </div>
          <h3 className="text-gray-500">Color variations inverted</h3>
          <div className="flex items-start flex-none">
            <Button inverted color="gray">Button</Button>&nbsp;
            <Button inverted color="green">Button</Button>&nbsp;
            <Button inverted color="pink">Button</Button>&nbsp;
            <Button inverted color="indigo">Button</Button>&nbsp;
            <Button inverted color="red">Button</Button>&nbsp;
            <Button inverted color="red" disabled>
              Disabled
            </Button>
            &nbsp;
            <Button inverted color="orange" loading>
              Loading
            </Button>
          </div>
          <h3 className="text-gray-500">Style variations</h3>
          <div className="flex items-start flex-none">
            <Button color="gray" simple>
              Simple
            </Button>
            &nbsp;
            <Button color="green" simple>
              Simple
            </Button>
            &nbsp;
            <Button color="red" simple>
              Simple
            </Button>
            &nbsp;
            <Button color="red" simple disabled>
              Simple Disabled
            </Button>
            &nbsp;
            <Button color="orange" simple loading>
              Simple Loading
            </Button>
            &nbsp;
            <Button color="orange" simple loading />
          </div>
          <h3 className="text-gray-500">Style variations inverted</h3>
          <div className="flex items-start flex-none">
            <Button inverted color="gray" simple>
              Simple
            </Button>
            &nbsp;
            <Button inverted color="green" simple>
              Simple
            </Button>
            &nbsp;
            <Button inverted color="red" simple>
              Simple
            </Button>
            &nbsp;
            <Button inverted color="red" simple disabled>
              Simple Disabled
            </Button>
            &nbsp;
            <Button inverted color="orange" simple loading>
              Simple Loading
            </Button>
            &nbsp;
            <Button inverted color="orange" simple loading />
          </div>
          <h3 className="text-gray-500">Size variations</h3>
          <div className="flex items-start flex-none">
            <Button color="green" size="small">
              Small
            </Button>
            &nbsp;
            <Button color="green" size="small" loading>
              Small
            </Button>
            &nbsp;
            <Button color="green" size="small" loading />
            &nbsp;
            <Button size="regular" color="red">
              regular
            </Button>
            &nbsp;
            <Button size="regular" color="red" loading>
              regular
            </Button>
            &nbsp;
            <Button size="big" color="orange">
              Big
            </Button>
            &nbsp;
            <Button size="big" color="orange" loading>
              Big
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
